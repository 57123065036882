/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-1{
  flex: 1;
}