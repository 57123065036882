/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import "../../node_modules/bootstrap/scss/_functions.scss";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import 'theme/_utilities.scss';
@import "../../node_modules/bootstrap/scss/variables";

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import "bootstrap";

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import "theme/theme";

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
@import "user";